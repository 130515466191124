import Scrollspy from 'react-scrollspy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faBlog, faBriefcase, faFile, faHouseDamage, faIdCardAlt } from '@fortawesome/free-solid-svg-icons';

export default () => (
  <header className="header-left" id="navbar-collapse-toggle">
    <div className="scroll-bar">
      <div className="hl-top">
        <div className="hl-logo">
          <a href="#">
            gR
                </a>
        </div>
      </div>
      <Scrollspy items={['home', 'resume']} currentClassName="active" className="nav nav-menu">
        <li>
          <a className="nav-link" href="#home" data-toggle="tooltip" title="HOME">
            <FontAwesomeIcon icon={faHouseDamage} size="2x" />
          </a>
        </li>
        <li>
          <a className="nav-link" href="#about" data-toggle="tooltip" title="ABOUT">
            <FontAwesomeIcon icon={faAddressCard} size="2x" />
          </a>
        </li>
        {/* <li>
          <a className="nav-link" href="#resume" data-toggle="tooltip" title="RESUME">
            <FontAwesomeIcon icon={faFile} size="2x" />
          </a>
        </li>
        <li>
          <a className="nav-link" href="#work" data-toggle="tooltip" title="WORK">
            <FontAwesomeIcon icon={faBriefcase} size="2x" />
          </a>
        </li>
        <li>
          <a className="nav-link" href="#blog" data-toggle="tooltip" title="BLOG">
            <FontAwesomeIcon icon={faBlog} size="2x" />
          </a>
        </li>
        <li>
          <a className="nav-link" href="#contactus" data-toggle="tooltip" title="CONTACT">
            <FontAwesomeIcon icon={faIdCardAlt} size="2x" />
          </a>
        </li> */}
      </Scrollspy>
    </div>
  </header>
);