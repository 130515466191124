import ProgressBar from 'react-bootstrap/ProgressBar';

const Home = () => (
  <main className="main-left">
    <section id="home" className="home-banner">
      <div className="hb-top-fixed d-flex">
        <div className="hb-info">
          <label>me@georgerujoiu.com</label>
        </div>
        {/* <div className="hb-lang">
          <ul className="nav">
            <li className="active"><a href="#">EN</a></li>
            <li><a href="#">RO</a></li>
          </ul>
        </div> */}
      </div>
      <div className="container">
        <div className="row full-screen align-items-center">
          <div className="col-lg-7">
            <div className="type-box">
              <h6>Hello, My name is</h6>
              <h1 className="font-alt">George Rujoiu</h1>
              <p className="lead">Full-stack Developer & DJ</p>
              <p className="desc">I have over 10 years of experience developing web applications &amp; services for small to enterprise businesses, also one of my hobbies is to mix different styles of music, thats why i became a DJ.</p>
              <div className="btn-bar">
                <a className="font-alt" href="https://www.linkedin.com/in/george-rujoiu/" target="__blank">Check my Linkedin Profile</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hb-me" style={{ backgroundImage: 'url(static/img/home-banner.jpg)' }}></div>
    </section>

    {/* Resume */}
    <section id="resume" className="section">
      <div className="container">
        <div className="title">
          <h3>Experience.</h3>
        </div>
        <div className="resume-box">
          <div className="resume-row">
            <div className="row">
              <div className="col-md-4 col-xl-3">
                <div className="rb-left">
                  <h6>Full Stack Developer</h6>
                  <label>Helpnet Farma</label>
                  <p>May 2021 - Present</p>
                  <div className="rb-time">Full Time</div>
                </div>
              </div>
              <div className="col-md-8 col-xl-9">
                <div className="rb-right">
                  <h6>About Company</h6>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div className="resume-row">
            <div className="row">
              <div className="col-md-4 col-xl-3">
                <div className="rb-left">
                  <h6>Full Stack Developer</h6>
                  <label>2Checkout / Verifone</label>
                  <p>May 2020 - May 2021</p>
                  <div className="rb-time">Full Time</div>
                </div>
              </div>
              <div className="col-md-8 col-xl-9">
                <div className="rb-right">
                  <h6>About Company</h6>
                  <p>"In August 2020, leading global payments solution provider Verifone acquired 2Checkout, further solidifying its commitment to providing seamless and frictionless experiences to customers globally through innovative and next-generation hardware and cloud services. 2Checkout will become Verifone, and the unified company will enable omnichannel commerce wherever and whenever clients shop."</p>
                </div>
              </div>
            </div>
          </div>
          <div className="resume-row">
            <div className="row">
              <div className="col-md-4 col-xl-3">
                <div className="rb-left">
                  <h6>Full Stack Developer</h6>
                  <label>Arnia Software</label>
                  <p>Sep 2017 - May 2020</p>
                  <div className="rb-time">Full Time</div>
                </div>
              </div>
              <div className="col-md-8 col-xl-9">
                <div className="rb-right">
                  <h6>About Company</h6>
                  <p>"Arnia is a leading software development company, founded in 2006. Our areas of expertise cover most of the current software technologies, frameworks and platforms for developing and implementing."</p>
                </div>
              </div>
            </div>
          </div>
          <div className="resume-row">
            <div className="row">
              <div className="col-md-4 col-xl-3">
                <div className="rb-left">
                  <h6>Full Stack Developer</h6>
                  <label>Stefanini EMEA</label>
                  <p>Sep 2016 - Sep 2017</p>
                  <div className="rb-time">Full Time</div>
                </div>
              </div>
              <div className="col-md-8 col-xl-9">
                <div className="rb-right">
                  <h6>About Company</h6>
                  <p>"We are a global company with 30 years of experience in the market, offering a robust selection of services such as automation, cloud, Internet of Things (IoT) and user experience (UX)."</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="separated"></div>

        <div className="title">
          <h3>Education & Skills</h3>
        </div>
        <div className="row">
          <div className="col-lg-4 m-15px-tb">
            <ul className="aducation-box">
              <li>
                <span>2011-Present</span>
                <h6>Full-stack developer / Software engineer</h6>
                <p>Worked for several companies</p>
              </li>
              <li>
                <span>2008-2011</span>
                <h6>Cybernetics, echonomics and statistical computer science</h6>
                <p>Petrol-Gaze University Ploiesti, Romania</p>
              </li>
            </ul>
          </div>
          <div className="col-lg-7 ml-auto m-15px-tb">
            <div className="skill-lt">
              <h6>HTML5</h6>
              <span>99%</span>
              <div className="skill-bar">
                <ProgressBar now={99} className="skill-bar-in" />
              </div>
            </div>
            <div className="skill-lt">
              <h6>PHP</h6>
              <span>90%</span>
              <div className="skill-bar">
                <ProgressBar now={99} className="skill-bar-in" />
              </div>
            </div>
            <div className="skill-lt">
              <h6>Javascript</h6>
              <span>90%</span>
              <div className="skill-bar">
                <ProgressBar now={90} className="skill-bar-in" />
              </div>
            </div>
            <div className="skill-lt">
              <h6>Frameworks and libraries</h6>
              <span>80%</span>
              <div className="skill-bar">
                <ProgressBar now={90} className="skill-bar-in" />
              </div>
            </div>
            <div className="skill-lt">
              <h6>Devops (Cloud: AWS / GCP)</h6>
              <span>75%</span>
              <div className="skill-bar">
                <ProgressBar now={75} className="skill-bar-in" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default Home;
